<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-if="switchIndex === 0">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-date-picker type="date" class="date" placeholder="时间" v-model="date">
            </el-date-picker>
            <el-select v-model="value" placeholder="检测时段">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="测量人">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input placeholder="姓名/床位" suffix-icon="el-icon-search" v-model="name">
            </el-input>
            <el-button size="small" @click="getList" class="blue">搜索</el-button>
            <el-button size="small" @click="clear" class="gray">重置</el-button>
          </div>
          <div class="right" @click="openAddXt">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <div class="talbe">
          <el-table :data="tableData" height="100%">
            <el-table-column prop="id" label="床号">
            </el-table-column>
            <el-table-column prop="name" label="患者姓名">
            </el-table-column>
            <el-table-column prop="xb" label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄">
            </el-table-column>
            <el-table-column prop="type1" label="科室">
            </el-table-column>
            <el-table-column prop="jcry" label="测量人">
            </el-table-column>
            <el-table-column prop="zzys" label="主治医师">
            </el-table-column>
            <el-table-column prop="jcrq" label="检测时间" width="200">
            </el-table-column>
            <el-table-column prop="jcsd" label="检测时段">
            </el-table-column>
            <el-table-column prop="jcsz" label="血糖值">
            </el-table-column>
            <el-table-column prop="fs" label="方式">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex">
                  <span @click="openUpdateXt">编辑</span>
                  <span>删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="query1.pageNum" background :page-size="query1.pageSize"
            layout="total, prev, pager, next, jumper" :total="query1.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 1">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-date-picker type="date" class="date" placeholder="时间" v-model="date">
            </el-date-picker>
            <el-button size="small" class="blue">最近一周</el-button>
            <el-button size="small" class="blue">最近一月</el-button>
            <el-input placeholder="姓名/床位" suffix-icon="el-icon-search" v-model="name">
            </el-input>
            <el-button size="small" @click="getList" class="blue">搜索</el-button>
            <el-button size="small" @click="clear" class="gray">重置</el-button>
          </div>
          <div class="right" @click="openAddXy">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%">
            <el-table-column prop="id" label="床号">
            </el-table-column>
            <el-table-column prop="name" label="患者姓名">
            </el-table-column>
            <el-table-column prop="xb" label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="jcrq" label="检测时间" width="300">
            </el-table-column>
            <el-table-column prop="jcszxyg" label="收缩压(mmHg)" width="170">
            </el-table-column>
            <el-table-column prop="jcszxyd" label="舒张压(mmHg)" width="170">
            </el-table-column>
            <el-table-column prop="jcszxl" label="心率(次/分)" width="170">
            </el-table-column>
            <el-table-column prop="xysp" label="血压水平">
            </el-table-column>
            <el-table-column prop="jcry" label="测量人">
            </el-table-column>
            <el-table-column prop="lrfs" label="录入方式">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex">
                  <span @click="openUpdateXy">编辑</span>
                  <span @click="openDeleteRecord">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 2">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <div class="left">
              <el-date-picker type="date" class="date" placeholder="时间" v-model="date">
              </el-date-picker>
              <el-button size="small" class="blue">最近一周</el-button>
              <el-button size="small" class="blue">最近一月</el-button>
              <el-input placeholder="姓名" suffix-icon="el-icon-search" v-model="name">
              </el-input>
              <el-button size="small" @click="getList" class="blue">搜索</el-button>
              <el-button size="small" @click="clear" class="gray">重置</el-button>
            </div>
          </div>
        </div>
        <div class="talbe">
          <el-table :data="tableData" height="100%">
            <el-table-column prop="id" label="床号">
            </el-table-column>
            <el-table-column prop="name" label="患者姓名">
            </el-table-column>
            <el-table-column prop="xb" label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="type1" label="科室">
            </el-table-column>
            <el-table-column prop="jcrq" label="检测时间">
            </el-table-column>
            <el-table-column prop="type1" label="体温(℃)">
            </el-table-column>
            <el-table-column prop="jcry" label="测量人">
            </el-table-column>
            <el-table-column prop="fs" label="测量方式">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex">
                  <span @click="openUpdateTw">编辑</span>
                  <span>删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 3">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <div class="left">
              <el-date-picker type="date" class="date" placeholder="时间" v-model="date">
              </el-date-picker>
              <el-button size="small" class="blue">最近一周</el-button>
              <el-button size="small" class="blue">最近一月</el-button>
              <el-date-picker type="date" placeholder="用药时段" v-model="name">
              </el-date-picker>
              <el-input placeholder="姓名" suffix-icon="el-icon-search">
              </el-input>
              <el-button size="small" @click="getList" class="blue">搜索</el-button>
              <el-button size="small" @click="clear" class="gray">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%">
            <el-table-column prop="id" label="床号">
            </el-table-column>
            <el-table-column prop="name" label="患者姓名">
            </el-table-column>
            <el-table-column prop="xb" label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄">
            </el-table-column>
            <el-table-column prop="type1" label="科室">
            </el-table-column>
            <el-table-column prop="type1" label="主治医师">
            </el-table-column>
            <el-table-column prop="type1" label="给药时间">
            </el-table-column>
            <el-table-column prop="type1" label="药品类型">
            </el-table-column>
            <el-table-column prop="type1" label="药品名">
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增血糖 -->
    <AddXt v-if="showAddXt" @close="closeAddXt"></AddXt>
    <!-- 血糖编辑 -->
    <UpdateXt v-if="showUpdateXt" @close="closeUpdateXt"></UpdateXt>
    <!-- 新增血压 -->
    <AddXy v-if="showAddXy" @close="closeAddXy"></AddXy>
    <!-- 血压编辑 -->
    <UpdateXy v-if="showUpdateXy" @close="closeUpdateXy"></UpdateXy>
    <!-- 删除记录 -->
    <DeleteRecord v-if="showDeleteRecord" @close="closeDeleteRecord"></DeleteRecord>
    <!-- 体温编辑 -->
    <UpdateTw v-if="showUpdateTw" @close="closeUpdateTw"></UpdateTw>
  </div>
</template>

<script>
import AddXt from '../patientRun/addXt.vue'
import UpdateXt from '../outpatientRun/updateXt.vue'
import AddXy from '../outpatientRun/addXy.vue'
import UpdateXy from './updateXy.vue'
import DeleteRecord from './deleteRecord.vue'
import UpdateTw from './updateTw.vue'
export default {
  components: {
    AddXt,
    UpdateXt,
    AddXy,
    UpdateXy,
    DeleteRecord,
    UpdateTw
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['血糖记录', '血压记录', '体温记录', '用药记录']),
      tableData: [],
      value: '',
      options: [],
      date: '',
      name: '',
      showAddXt: false,
      showUpdateXt: false,
      showAddXy: false,
      showUpdateXy: false,
      showDeleteRecord: false,
      showUpdateTw: false,
      list1: [],
      showList1: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
      this.name = ''
      this.date = ''
      this.getList()
    },
    // 服务记录
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591809
      try {
        const obj = {
          sfzh: '', // 身份证号
          sbbm: '', // 设备编码
          sbxh: '', // 设备型号 xy (血压） xt (血糖)
          ssjg: '', // 所属机构
          jcry: '', // 检测人员
          jcjg: '', // 检测机构
          jgcmhid: '', // 机构村id
          jgxzjdid: '', // 机构乡镇id
          lytype: '' // 检测界面 档案管理 传 jkdn, 体检管理列表 传 jktj,医防融合传 yfrh，体质辨识 zydd,诊疗服务 zlfw,数据上报 sjsb
        }
        const { data: res } = await this.$http.post('/api/jkct/app/findfzjc', obj)
        console.log('智能监测', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = this.list1.length
      } catch (error) {
        console.log('服务记录请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    clear () {
      this.name = ''
      this.date = ''
    },
    // 显示
    getShowList1 () {
      this.showList1 = []
      this.showList1 = this.list1.slice((this.query1.pageNum - 1) * this.query1.pageSize, this.query1.pageNum * this.query1.pageSize)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getShowList1()
    },
    openAddXt () {
      this.showAddXt = true
    },
    closeAddXt () {
      this.showAddXt = false
    },
    openUpdateXt () {
      this.showUpdateXt = true
    },
    closeUpdateXt () {
      this.showUpdateXt = false
    },
    openAddXy () {
      this.showAddXy = true
    },
    closeAddXy () {
      this.showAddXy = false
    },
    openUpdateXy () {
      this.showUpdateXy = true
    },
    closeUpdateXy () {
      this.showUpdateXy = false
    },
    openDeleteRecord () {
      this.showDeleteRecord = true
    },
    closeDeleteRecord () {
      this.showDeleteRecord = false
    },
    openUpdateTw () {
      this.showUpdateTw = true
    },
    closeUpdateTw () {
      this.showUpdateTw = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: 40px;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;
      border-bottom: none;

      .title {
        color: #01EEFD;
        font-size: 20px;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;

        .count-item {
          display: flex;
          align-items: center;
          width: 360px;
          height: 40px;
          margin-right: 20px;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: 18px;

          img {
            width: 30px;
            height: 30px;
            margin: 0 20px;
          }
        }
      }

      .echarts {
        width: 398px;
        height: 330px;
      }

      .echarts1 {
        width: 40%;
        height: 330px;
      }

      .echarts2 {
        width: 100%;
        height: 395px;
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .width {
          width: 246px;
        }

        .el-select {
          width: 120px;
        }
      }
    }

    .line1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      color: #01EEFD;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-left: 10px;
          }

          .date {
            margin-left: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .talbe {
        width: 100%;
        height: calc(100% - 150px);
      }
    }
  }
}
</style>
